<template>
  <div class="justify-start">
    <RdvListItem
      v-for="(list, epoch) in listByDay"
      :key="epoch"
      :day="epoch | toDate"
      :rdv-list="list"
      :init-open="defaultOpenDay === epoch"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import groupBy from "lodash-es/groupBy";
import keys from "lodash-es/keys";
import first from "lodash-es/first";
import RdvListItem from "@/components/RdvListItem.vue";

export default Vue.extend({
  components: {
    RdvListItem
  },
  props: {
    rdvList: {
      type: Array,
      required: true
    }
  },
  filters: {
    toDate: epoch => {
      const d = new Date();
      d.setTime(epoch);
      return d;
    }
  },
  computed: {
    listByDay() {
      return groupBy(this.rdvList, rdv => new Date(rdv.day).getTime());
    },
    defaultOpenDay() {
      return first(keys(this.listByDay));
    }
  }
});
</script>
