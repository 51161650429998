import { isValid } from "date-fns";
import isArray from "lodash-es/isArray";
import isNil from "lodash-es/isNil";
import reduce from "lodash-es/reduce";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiEntity {
  [index: string]: any;
  fill(apiObject: object) {
    Object.assign(this, apiObject);
  }

  castDate(date?: Date | string) {
    if (date) {
      const d = new Date(date);
      return isValid(d) ? d : undefined;
    }

    return undefined;
  }

  static createInstance(data: object | Array<object>, self: any) {
    if (isNil(data)) {
      return data;
    }

    return isArray(data)
      ? reduce(
          data,
          (acc: Array<object>, d?: object) => {
            if (d) {
              acc.push(new self(d));
            }
            return acc;
          },
          []
        )
      : new self(data);
  }
}
