



















import Vue from "vue";
import { addWeeks, startOfWeek, endOfWeek } from "date-fns";
import Activity from "@/models/Activity";
import RdvOffer from "@/models/RdvOffer";
import RdvLayout from "@/layouts/RdvLayout.vue";
import RdvList from "@/components/RdvList.vue";
import DialogNotAvailable from "@/components/DialogNotAvailable.vue";
import DialogValidate from "@/components/DialogValidate.vue";
import LoadMore from "@/components/LoadMore.vue";

interface Data {
  dialogNotAvailable: boolean;
  dialogValidate: boolean;
  requestId?: string;
  requestDate?: Date;
  offers: Array<RdvOffer>;
  fetchCount: number;
  start: Date;
}

const FETCH_LIMIT = 5;

export default Vue.extend({
  components: {
    DialogNotAvailable,
    DialogValidate,
    RdvLayout,
    RdvList,
    LoadMore
  },
  data: (): Data => ({
    dialogNotAvailable: false,
    dialogValidate: false,
    requestId: undefined,
    requestDate: undefined,
    offers: [],
    fetchCount: 0,
    start: startOfWeek(new Date())
  }),
  computed: {
    activity(): Activity {
      return Activity.find(this.$route.params.id);
    },
    selected(): RdvOffer | undefined {
      return this.$store.getters.selectedOffer;
    },
    title(): string | undefined {
      return this.activity ? this.activity.client.name : undefined;
    },
    limitReach(): boolean {
      return this.fetchCount === FETCH_LIMIT;
    }
  },
  watch: {
    selected(v) {
      if (v) {
        this.dialogValidate = true;
      }
    }
  },
  async created() {
    const { appointment, place } = await this.$store.dispatch("get", {
      type: "delivery",
      id: this.$route.params.id
    });
    if (appointment.state === "confirmed") {
      this.$router.push({
        name: "Success",
        params: {
          id: this.$route.params.id
        }
      });
    } else {
      await this.nextProposal(place);
    }
  },
  methods: {
    async nextProposal(place?: object) {
      const offers = await this.getAppointmentsProposal(place);
      this.start = addWeeks(this.start, 1);
      this.fetchCount += 1;
      if (!offers.length && !this.limitReach) {
        this.nextProposal(place);
      }
    },
    async getAppointmentsProposal(place?: object) {
      const { data } = await this.$axios.post("appointment", {
        place: place || this.activity.place,
        start: this.start,
        end: endOfWeek(this.start)
      });
      this.offers = this.offers.concat(
        data.offers.map(
          (offer: RdvOffer) => new RdvOffer(offer, data.request_id)
        )
      );
      this.requestId = data.request_id;
      this.requestDate = new Date(data.request_date);

      return data.offers;
    },
    /* eslint-disable @typescript-eslint/camelcase, no-console*/
    async reserveOffer(offer: RdvOffer) {
      try {
        await this.$axios.patch("appointment/reserve", {
          request_id: offer.requestId,
          offer_id: offer.id
        });
        this.$store.dispatch("setSelectedOffer", offer);
      } catch (error) {
        console.warn(error);
      }
    },
    async releaseOffer({ id, requestId }: RdvOffer) {
      try {
        await this.$axios.patch("appointment/release", {
          request_id: requestId,
          offer_id: id
        });
        this.$store.dispatch("setSelectedOffer");
      } catch (error) {
        console.warn(error);
      }
    },
    async confirmOffer() {
      if (this.selected) {
        await this.$store.dispatch("patch", {
          type: "delivery",
          id: this.activity.id,
          url: "appointment/confirm",
          data: {
            request_id: this.selected.requestId,
            offer_id: this.selected.id,
            activity_id: this.activity.id,
            start: this.selected.start,
            end: this.selected.end
          }
        });
        this.$router.push({
          name: "Info",
          params: {
            id: this.$route.params.id
          }
        });
      }
    },
    /* eslint-enable @typescript-eslint/camelcase, no-console*/
    async handleSelected(offer: RdvOffer) {
      if (!this.selected || offer.id !== this.selected.id) {
        this.reserveOffer(offer);
      } else {
        this.releaseOffer(offer);
      }
    }
  }
});
