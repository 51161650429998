import Range from "./Range";
import { RdvOfferObject } from "./types";

export default class RdvOffer extends Range {
  id: string;
  requestId: string;

  constructor(obj: RdvOfferObject, requestId: string) {
    super(obj);
    this.id = obj.id;
    this.requestId = requestId;
  }
}
