





















































import Vue from "vue";
import Survey from "@/models/Survey";
import { ReceptionOptions, TypeOptions } from "@/models/enums";
import SurveyLayout from "@/layouts/SurveyLayout.vue";

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
};

export default Vue.extend({
  components: {
    SurveyLayout
  },
  data: () => ({
    valid: true,
    loading: false,
    reception: undefined,
    receptionOptions: [
      ReceptionOptions.YES,
      ReceptionOptions.REFUSED,
      ReceptionOptions.NO
    ],
    type: undefined,
    typeOptions: [TypeOptions.PRO, TypeOptions.INDIVIDUAL],
    rating: undefined,
    suggestions: undefined
  }),
  computed: {
    form() {
      return this.$refs.form as VForm;
    }
  },
  methods: {
    async send() {
      if (this.form.validate()) {
        try {
          this.loading = true;
          await this.$store.dispatch("post", {
            type: "survey",
            id: this.$route.params.id,
            data: new Survey({
              reception: this.reception,
              type: this.type,
              rating: this.rating,
              suggestions: this.suggestions
            })
          });
          this.$router.replace({
            name: "Success"
          });
        } catch (error) {
          this.$store.dispatch("setError", "Problème d'envoi");
        } finally {
          this.loading = true;
        }
      }
    },
    required(v?: number) {
      return (v !== undefined && v >= 0) || "Ce champ est obligatoire";
    }
  }
});
