import Vue from "vue";
import VueI18n from "vue-i18n";

import messages from "./lang/all";

Vue.use(VueI18n);

// const locale = localStorage.getItem("lang") || window.navigator.language;
const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: process.env.VUE_APP_FALLBACK_LANG || "en",
  messages
});

export default i18n;
