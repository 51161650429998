import { fr } from "date-fns/locale";
import { ReceptionOptions, TypeOptions } from "@/models/enums";

/* eslint-disable @typescript-eslint/camelcase */
export default {
  date: fr,
  survey: {
    receptionOptions: {
      [ReceptionOptions.YES]: "oui",
      [ReceptionOptions.REFUSED]: "oui, mais colis refusé",
      [ReceptionOptions.NO]: "non"
    },
    typeOptions: {
      [TypeOptions.INDIVIDUAL]: "un particulier",
      [TypeOptions.PRO]: "un professionel"
    }
  }
};
