














import Vue from "vue";
import RdvLayout from "@/layouts/RdvLayout.vue";

export default Vue.extend({
  components: {
    RdvLayout
  },
  data: () => ({
    id: ""
  }),
  methods: {
    async search() {
      try {
        await this.$store.dispatch("get", {
          type: "delivery",
          id: this.id
        });
        this.$router.push({
          name: "Rdv",
          params: {
            id: this.id
          }
        });
      } catch (error) {
        if (error.response.status === 404) {
          this.$store.dispatch("setError", "Référence introuvable.");
        }
      }
    }
  }
});
