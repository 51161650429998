<template>
  <v-card tile width="100%" class="rdv-list-item elevation-0 mb-4">
    <v-card-title class="font-weight-regular body-1 justify-space-between">
      {{ day | format("EEEE d MMMM") | upperFirst }}
      <v-btn class="more-btn elevation-0" tile dark @click="toggle">
        {{ open ? "-" : "+" }}
      </v-btn>
    </v-card-title>

    <v-divider v-show="open" class="mx-4"></v-divider>

    <v-card-text v-show="open">
      <TimeRange
        v-for="(rdv, idx) in truncated"
        :key="idx"
        :rdv="rdv"
        @select="$emit('select', $event)"
      />
    </v-card-text>

    <v-divider
      v-show="open && shouldTruncate && !more"
      class="mx-4"
    ></v-divider>

    <v-card-actions
      v-show="open && shouldTruncate && !more"
      class="justify-center"
    >
      <v-btn text class="primary--text" @click="more = true">
        voir plus
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import TimeRange from "@/components/TimeRange.vue";

export default Vue.extend({
  components: { TimeRange },
  props: {
    initOpen: {
      type: Boolean,
      default: false
    },
    day: {
      type: Date,
      required: true
    },
    rdvList: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 6
    }
  },
  data: () => ({
    open: false,
    more: false
  }),
  computed: {
    shouldTruncate() {
      return this.rdvList.length > this.limit;
    },
    truncated() {
      if (this.shouldTruncate && !this.more) {
        return this.rdvList.slice(0, this.limit);
      }
      return this.rdvList;
    }
  },
  created() {
    this.open = this.initOpen;
  },
  methods: {
    toggle() {
      this.open = !this.open;
      if (!this.open) {
        this.more = false;
      }
    }
  }
});
</script>

<style scoped>
.rdv-list-item {
  border-left: 14px solid var(--v-primary-base) !important;
}
.more-btn {
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  padding: 0px !important;
  font-size: 18px;
  font-weight: 200;
}
</style>
