import { startOfDay } from "date-fns";
import filters from "@/plugins/filters";
import { RangeObject } from "./types";

export default class Range {
  start: Date;
  end: Date;

  constructor(obj: RangeObject) {
    this.start = new Date(obj.start);
    this.end = new Date(obj.end);
  }
  get range() {
    return `${filters.format(this.start, "HH:mm")} - ${filters.format(
      this.end,
      "HH:mm"
    )}`;
  }
  get day() {
    return startOfDay(this.start);
  }
}
