import Range from "./Range";
import { RdvObject } from "./types";

export default class Rdv extends Range {
  state: string;

  constructor(obj: RdvObject) {
    super(obj);
    this.state = obj.state;
  }
}
