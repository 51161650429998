




































import Vue from "vue";
import { mapGetters } from "vuex";
import { mdiPhone } from "@mdi/js";
import MenuLayout from "@/layouts/MenuLayout.vue";

export default Vue.extend({
  components: {
    MenuLayout
  },
  data: () => ({
    iconPhone: mdiPhone
  }),
  computed: {
    ...mapGetters(["hotlinePhone", "hotlineEmail"])
  },
  created() {
    document.title = "Service support";
  }
});
