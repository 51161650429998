import { format } from "date-fns";
import { fr } from "date-fns/locale";
import upperFirst from "lodash-es/upperFirst";

interface Filters {
  [key: string]: Function;
}

const filters: Filters = {
  format: (date: Date, dateFormat: string) => {
    return format(date, dateFormat, { locale: fr });
  },
  upperFirst: (v: string) => upperFirst(v)
};

export default filters;
