











































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import MenuLayout from "@/layouts/MenuLayout.vue";

export default Vue.extend({
  components: {
    MenuLayout
  },
  data: () => ({
    siteCompanyName: "LEO ANTARES",
    siteCompanyEmail: "logitricks@antares.fr"
  }),
  computed: {
    ...mapGetters([
      "clientName",
      "clientCompanyName",
      "clientAddress",
      "clientDataOfficerEmail"
    ]),
    siteUrl() {
      return window.origin;
    }
  },
  created() {
    document.title = "Mentions légales";
  }
});
