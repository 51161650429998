import Vue from "vue";
import Vuex from "vuex";
import { ApiStorePlugin } from "vuex-rest-plugin";
import axios from "@/services/axios";
import models from "@/models/api-models";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { app },
  plugins: [
    ApiStorePlugin({
      axios,
      namespaced: false,
      models,
      dataPath: "data"
    })
  ]
});
