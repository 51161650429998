


































import Vue from "vue";
import { mdiClose } from "@mdi/js";
import RdvOffer from "@/models/RdvOffer";
import HeroRdv from "@/components/HeroRdv.vue";

export default Vue.extend({
  components: { HeroRdv },
  model: {
    prop: "display",
    event: "change"
  },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    rdv: {
      type: RdvOffer
    }
  },
  data: () => ({
    iconClose: mdiClose
  })
});
