











import Vue from "vue";
import Layout from "./Layout.vue";

export default Vue.extend({
  components: {
    Layout
  },
  props: {
    dark: {
      type: Boolean,
      default: true
    },
    appBarClass: {
      type: String,
      default: "secondary--text"
    },
    bgClass: {
      type: String,
      default: "bg"
    }
  },
  created() {
    document.title = "Rendez-vous";
  }
});
