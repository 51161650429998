











import Vue from "vue";
import Rdv from "@/models/Rdv";
import RdvOffer from "@/models/RdvOffer";

export default Vue.extend({
  props: {
    rdv: {
      type: [Rdv, RdvOffer],
      required: true
    }
  }
});
