import ApiEntity from "./ApiEntity";
import { ReceptionOptions, TypeOptions } from "@/models/enums";

interface SurveyData {
  id?: string;
  client_id?: string;
  reception?: ReceptionOptions;
  type?: TypeOptions;
  rating?: number;
  suggestions?: string;
}

/* eslint-disable @typescript-eslint/camelcase */
export default class Survey extends ApiEntity {
  id?: string;
  client_id?: string;
  reception?: ReceptionOptions;
  type?: TypeOptions;
  rating?: number;
  suggestions?: string;

  constructor(o: SurveyData) {
    super();
    this.id = o.id;
    this.client_id = o.client_id;
    this.reception = o.reception;
    this.type = o.type;
    this.rating = o.rating;
    this.suggestions = o.suggestions;
  }

  static create(data: object | Array<Survey>) {
    return Survey.createInstance(data, Survey);
  }
}
