





















import Vue from "vue";
import { mdiChevronLeft } from "@mdi/js";
import DialogMenu from "@/components/DialogMenu.vue";
import ErrorBar from "@/components/Error.vue";

export default Vue.extend({
  components: {
    DialogMenu,
    ErrorBar
  },
  props: {
    title: {
      type: String,
      default: "RDV"
    },
    dark: {
      type: Boolean,
      default: true
    },
    fluid: {
      type: Boolean,
      default: false
    },
    appBarClass: {
      type: String,
      default: "secondary--text"
    },
    bgClass: {
      type: String,
      default: "bg"
    }
  },
  data: () => ({
    displayMenu: false,
    iconChevronLeft: mdiChevronLeft
  }),
  computed: {
    isRoot(): boolean {
      return this.$route.meta.withMenu;
    }
  },
  methods: {
    handleNav() {
      if (this.isRoot) {
        this.displayMenu = true;
      } else {
        this.$router.back();
      }
    }
  }
});
