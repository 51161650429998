import { LocaleMessages } from "vue-i18n";
import fr from "./fr";
import en from "./en";

const messages: LocaleMessages = {
  en,
  fr
};

export default messages;
