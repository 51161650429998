import {
  ActionTree,
  GetterTree,
  MutationTree,
  Module,
  ActionContext
} from "vuex";
import RdvOffer from "@/models/RdvOffer";

interface Commons {
  clientName: string;
  clientCompanyName: string;
  clientAddress: string;
  clientDataOfficerEmail: string;
  hotlinePhone?: string;
  hotlineEmail: string;
}

interface AppState extends Commons {
  selectedOffer?: RdvOffer;
  error: string;
  blacklist?: object;
  client?: object;
}

export const state: AppState = {
  selectedOffer: undefined,
  error: "",
  clientName: "",
  clientCompanyName: "",
  clientAddress: "",
  clientDataOfficerEmail: "",
  hotlinePhone: undefined,
  hotlineEmail: ""
};

export const getters: GetterTree<AppState, {}> = {
  selectedOffer: (myState: AppState) => {
    return myState.selectedOffer;
  },
  error: (myState: AppState) => {
    return myState.error;
  },
  clientName: (myState: AppState) => {
    return myState.clientName;
  },
  clientCompanyName: (myState: AppState) => {
    return myState.clientCompanyName;
  },
  clientAddress: (myState: AppState) => {
    return myState.clientAddress;
  },
  clientDataOfficerEmail: (myState: AppState) => {
    return myState.clientDataOfficerEmail;
  },
  hotlinePhone: (myState: AppState) => {
    return myState.hotlinePhone;
  },
  hotlineEmail: (myState: AppState) => {
    return myState.hotlineEmail;
  }
};

export const mutations: MutationTree<AppState> = {
  SET_SELECTED_OFFER: (myState: AppState, offer: RdvOffer): void => {
    myState.selectedOffer = offer;
  },
  SET_COMMONS: (myState: AppState, commons: Commons): void => {
    myState = Object.assign(myState, commons);
  },
  SET_ERROR: (myState: AppState, error: string): void => {
    myState.error = error;
  }
};

// eslint-disable-next-line
export const actions: ActionTree<AppState, any> = {
  setSelectedOffer: (
    { commit }: ActionContext<AppState, RdvOffer>,
    offer: RdvOffer
  ): void => {
    commit("SET_SELECTED_OFFER", offer);
  },
  setCommons: (
    { commit }: ActionContext<AppState, string>,
    commons: Commons
  ): void => {
    commit("SET_COMMONS", commons);
  },
  setError: (
    { commit }: ActionContext<AppState, string>,
    error: string
  ): void => {
    commit("SET_ERROR", error);
  }
};

const app: Module<AppState, {}> = {
  state,
  getters,
  actions,
  mutations
};

export default app;
