import Home from "../views/rdv/Home.vue";
import Rdv from "../views/rdv/Rdv.vue";
import Info from "../views/rdv/Info.vue";
import Success from "../views/rdv/Success.vue";

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      withMenu: true
    }
  },
  {
    path: "/:id",
    children: [
      {
        path: "",
        name: "Rdv",
        component: Rdv,
        meta: {
          withMenu: true
        }
      },
      {
        name: "Info",
        path: "info",
        component: Info
      },
      {
        path: "success",
        name: "Success",
        component: Success
      }
    ]
  }
];
