











import Vue from "vue";
import Layout from "./Layout.vue";

export default Vue.extend({
  components: {
    Layout
  },
  props: {
    title: {
      type: String
    }
  }
});
