export enum ReceptionOptions {
  YES,
  REFUSED,
  NO
}

export enum TypeOptions {
  PRO,
  INDIVIDUAL
}
