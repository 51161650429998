

















































import Vue from "vue";
import { mdiClose } from "@mdi/js";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  components: { Footer },
  model: {
    prop: "display",
    event: "change"
  },
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    id: "12456121271212d",
    iconClose: mdiClose
  }),
  methods: {
    async unsubscribe() {
      try {
        await this.$axios.post(`blacklist/${this.$route.params.id}`);
        this.$router.replace({
          name: "Success",
          params: { id: this.$route.params.id }
        });
      } catch (error) {
        this.$store.dispatch("setError", error);
      }
    }
  }
});
