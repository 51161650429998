import ApiEntity from "./ApiEntity";
import store from "@/store";

export default class Client extends ApiEntity {
  id: string;
  name: string;
  hotline?: string;

  constructor(o: Client) {
    super();
    this.id = o.id;
    this.name = o.name;
    this.hotline = o.hotline;
  }

  static create(data: object | Array<Client>) {
    return Client.createInstance(data, Client);
  }
  static first() {
    const clients = store.getters.clients.items;
    return clients[Object.keys(clients)[0]];
  }
}
