




































import Vue from "vue";
import RdvLayout from "@/layouts/RdvLayout.vue";
import Activity from "@/models/Activity";

export default Vue.extend({
  components: { RdvLayout },
  data() {
    return {
      phone: "",
      intercom: "",
      comments: "",
      hasIntercom: false
    };
  },
  created() {
    const activity = Activity.find(this.$route.params.id);
    this.phone = activity.receiver_phone;
    this.comments = activity.receiver_text;
    this.intercom = activity.intercom;
    this.hasIntercom = activity.hasIntercom;
  },
  methods: {
    async submit() {
      await this.$store.dispatch("patch", {
        type: "delivery",
        id: this.$route.params.id,
        data: {
          phone: this.phone,
          intercom: this.intercom,
          hasIntercom: this.hasIntercom,
          comments: this.comments
        }
      });
      this.$router.push({
        name: "Success",
        params: {
          id: this.$route.params.id
        }
      });
    }
  }
});
