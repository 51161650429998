




























import Vue from "vue";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  model: {
    prop: "display",
    event: "change"
  },
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    iconClose: mdiClose
  })
});
