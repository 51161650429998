import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/src/locale/fr";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr"
  },
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#50e2c0",
        secondary: "#34373c",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
