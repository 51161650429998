import Vue from "vue";
import VueRouter from "vue-router";
import Support from "../views/Support.vue";
import Legal from "../views/Legal.vue";
import RdvRoutes from "./rdv";
import SurveyRoutes from "./survey";

Vue.use(VueRouter);

const hostConfig = new Map();
hostConfig.set("rdv", RdvRoutes);
hostConfig.set("survey", SurveyRoutes);
const host = window.location.host;
const parts = host.split(".");

const commonRoutes = [
  {
    path: "/support",
    name: "Support",
    component: Support
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal
  }
];

const routes = commonRoutes.concat(hostConfig.get(parts[0]));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
