import { render, staticRenderFns } from "./RdvListItem.vue?vue&type=template&id=563d3f30&scoped=true&"
import script from "./RdvListItem.vue?vue&type=script&lang=js&"
export * from "./RdvListItem.vue?vue&type=script&lang=js&"
import style0 from "./RdvListItem.vue?vue&type=style&index=0&id=563d3f30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563d3f30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDivider})
