



























import Vue from "vue";
import { mdiCalendar } from "@mdi/js";
import Activity from "@/models/Activity";
import Rdv from "@/models/Rdv";
import HeroRdv from "@/components/HeroRdv.vue";
import DialogRefuse from "@/components/DialogRefuse.vue";
import RdvLayout from "@/layouts/RdvLayout.vue";

export default Vue.extend({
  components: { RdvLayout, HeroRdv, DialogRefuse },
  data: () => ({
    svg: require("@/assets/icon-validation.svg"),
    calendar: undefined,
    iconCalendar: mdiCalendar
  }),
  computed: {
    activity(): Activity {
      return Activity.find(this.$route.params.id);
    },
    rdv(): Rdv | undefined {
      return this.activity ? this.activity.appointment : undefined;
    }
  },
  async created() {
    const data = await this.$store.dispatch("get", {
      type: "delivery",
      id: this.$route.params.id
    });
    if (data.appointment.state !== "confirmed") {
      this.$router.push({
        name: "Rdv",
        params: {
          id: this.$route.params.id
        }
      });
    }
  },
  methods: {
    getCalendar(): void {
      if (this.rdv) {
        this.$ics.addEvent(
          "fr-fr",
          "Livraison",
          "Rendez-vous pour ma livraison",
          "Nantes",
          this.rdv.start.toString(),
          this.rdv.end.toString()
        );
        this.$ics.download("mon-rdv");
      }
    },
    async refuse() {
      await this.$store.dispatch("patch", {
        type: "delivery",
        id: this.activity.id,
        url: "appointment/refuse",
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          activity_id: this.activity.id
        }
      });
      this.$router.push({
        name: "Rdv",
        params: {
          id: this.$route.params.id
        }
      });
    }
  }
});
