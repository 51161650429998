import _Vue from "vue";
import axios from "../services/axios";
import { AxiosInstance } from "axios";
import i18n from "../i18n";

const instance: AxiosInstance = axios;
export default {
  install(Vue: typeof _Vue): void {
    // Add a request interceptor
    instance.defaults.headers.common["Accept-Language"] = i18n.locale;
    Vue.prototype.$axios = instance;
  }
};
