<template>
  <div class="justify-start">
    <v-btn
      v-if="!end"
      text
      class="text-center primary--text mt-4"
      width="100%"
      @click="$emit('click')"
    >
      afficher plus de disponibilités
    </v-btn>
    <div v-else class="d-flex flex-column mt-6">
      <p class="text-center primary--text subtitle-2">
        Désolé, plus aucune disponibilité
      </p>
      <v-btn
        v-if="phone"
        tile
        :href="`tel:${phone}`"
        class="text-center primary"
        width="100%"
      >
        <v-icon class="mr-2" size="16px">{{ iconPhone }}</v-icon>
        nous appeler
      </v-btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mdiPhone } from "@mdi/js";

export default Vue.extend({
  props: {
    end: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String
    }
  },
  data: () => ({
    iconPhone: mdiPhone
  })
});
</script>
