import { ModelTypeTree, ApiState } from "vuex-rest-plugin";
import Activity from "./Activity";
import Client from "./Client";
import Survey from "./Survey";

const models: typeof ModelTypeTree = {
  client: {
    name: "CLIENT",
    plural: "CLIENTS",
    type: new ApiState(),
    timeout: 60,
    afterGet: (data: object | Array<object>) => Client.create(data)
  },
  delivery: {
    name: "DELIVERY",
    plural: "DELIVERIES",
    type: new ApiState(),
    afterGet: (data: object | Array<object>) => Activity.create(data),
    references: {
      client: "client"
    }
  },
  survey: {
    name: "SURVEY",
    plural: "SURVEYS",
    type: new ApiState(),
    afterGet: (data: object | Array<object>) => Survey.create(data)
  }
};

export default models;
