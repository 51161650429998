import { enGB } from "date-fns/locale";
import { ReceptionOptions, TypeOptions } from "@/models/enums";

/* eslint-disable @typescript-eslint/camelcase */
export default {
  date: enGB,
  survey: {
    receptionOptions: {
      [ReceptionOptions.YES]: "yes",
      [ReceptionOptions.REFUSED]: "yes, but parcel refused",
      [ReceptionOptions.NO]: "no"
    },
    typeOptions: {
      [TypeOptions.INDIVIDUAL]: "an individual",
      [TypeOptions.PRO]: "a professional"
    }
  }
};
