










import Vue from "vue";
import SurveyLayout from "@/layouts/SurveyLayout.vue";

export default Vue.extend({
  components: {
    SurveyLayout
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const id = this.$route.params.id;
        const {
          data: {
            data: {
              completed,
              default_app: {
                name: clientName,
                company_name: clientCompanyName,
                address: clientAddress,
                client_data_officer_email: clientDataOfficerEmail,
                hotline_email: hotlineEmail,
                hotline_phone: hotlinePhone
              }
            }
          }
        } = await this.$axios.get(`survey/${id}/create`);

        this.$store.dispatch("setCommons", {
          clientName,
          clientCompanyName,
          clientAddress,
          clientDataOfficerEmail,
          hotlinePhone,
          hotlineEmail
        });

        this.$router.replace({
          name: completed ? "Success" : "Survey",
          params: { id }
        });
      } catch (error) {
        this.$store.dispatch("setError", "Problème de réseau");
      }
    }
  }
});
