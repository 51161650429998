











import Vue from "vue";
import RdvOffer from "@/models/RdvOffer";

export default Vue.extend({
  props: {
    rdv: RdvOffer
  },
  computed: {
    isSelected(): boolean {
      return this.$store.getters.selectedOffer === this.rdv;
    }
  }
});
