import Vue from "vue";
import ICS from "vue-ics";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import filters from "./plugins/filters";
import "./styles/main.scss";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.use(axios);
Vue.use(ICS, {
  uidDomain: "logitricks.com",
  prodId: "logitricks"
});

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
