import Home from "../views/survey/Home.vue";
import Survey from "../views/survey/Survey.vue";
import Success from "../views/survey/Success.vue";

const meta = {
  withMenu: true
};

export default [
  {
    path: "/:id",
    name: "Home",
    component: Home,
    meta
  },
  {
    path: "/:id/survey",
    name: "Survey",
    component: Survey,
    meta
  },
  {
    path: "/:id/success",
    name: "Success",
    component: Success,
    meta
  }
];
