




















import Vue from "vue";
import SurveyLayout from "@/layouts/SurveyLayout.vue";

export default Vue.extend({
  components: {
    SurveyLayout
  },
  data: () => ({
    svg: require("@/assets/icon-validation.svg")
  })
});
