import find from "lodash-es/find";
import ApiEntity from "./ApiEntity";
import Client from "./Client";
import Rdv from "./Rdv";
import { objectFromArray } from "./utils";
import store from "@/store";

/* eslint-disable @typescript-eslint/camelcase, @typescript-eslint/no-explicit-any */
export default class Activity extends ApiEntity {
  private attributes: Array<string> = [];
  id: string;
  short_id: string;
  state: string;
  external_id: string;
  receiver_name?: string;
  receiver_phone?: string;
  comments?: string;
  intercom?: string;
  hasIntercom = false;
  place: object;
  appointment?: Rdv;
  client: Client;
  client_id: string;
  duration: number;
  constructor(o: any) {
    super();
    this.id = o.id;
    this.short_id = o.short_id;
    this.state = o.state;
    this.external_id = o.external_id;
    this.receiver_name = o.receiver_name;
    this.receiver_phone = o.receiver_phone;
    this.comments = o.comments;
    this.intercom = o.intercom;
    this.hasIntercom = o.hasIntercom;
    this.place = o.place;
    this.appointment = new Rdv(o.appointment);
    this.client = new Client(o.client);
    this.client_id = o.client_id;
    this.duration = o.duration;
    this.attributes = Object.getOwnPropertyNames(this);
  }

  toJSON() {
    return objectFromArray(this, this.attributes);
  }

  static create(data: object | Array<object>) {
    return Activity.createInstance(data, Activity);
  }

  static find(id: string) {
    return (
      store.getters.deliveries.items[id] ||
      find(store.getters.deliveries.items, ["short_id", id])
    );
  }
}
