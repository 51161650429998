









import Vue from "vue";

export default Vue.extend({
  data: () => ({
    snackbar: false
  }),
  computed: {
    errorText(): string {
      return this.$store.getters.error;
    }
  },
  watch: {
    errorText(v) {
      if (v.length) {
        this.snackbar = true;
      }
    },
    snackbar(v) {
      if (!v) {
        this.$store.dispatch("setError", "");
      }
    }
  }
});
